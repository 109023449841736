import React from 'react';
import { Text, Image, StyleSheet, TouchableOpacity, ScrollView, View } from 'react-native';
import demoImage from './assets/palm-demo.jpg';

const GuideScreen = ({ navigation }) => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>How to Capture a Good Palm Image</Text>
      </View>

      <View style={styles.instructions}>
        <Text style={styles.subtitle}>
          Follow these tips for the best palm reading results:
        </Text>
        <Text style={styles.tip}>• Use natural, even lighting.</Text>
        <Text style={styles.tip}>• Place your hand on a solid-color background.</Text>
        <Text style={styles.tip}>• Include your entire palm and fingers in the shot.</Text>
        <Text style={styles.tip}>• Avoid shadows and blurriness.</Text>
      </View>

      <Image source={demoImage} style={styles.demoImage} />

      <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Capture')}>
        <Text style={styles.buttonText}>Proceed to Capture</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: '#1c0436', // dark purple background
  },
  header: {
    marginBottom: 30,
    paddingHorizontal: 10,
  },
  title: {
    fontSize: 26,
    color: '#3baee5', // bright blue for titles
    fontWeight: 'bold',
    textAlign: 'center',
  },
  instructions: {
    backgroundColor: '#2c1646', // a slightly lighter shade of purple for the instruction panel
    borderRadius: 15,
    padding: 20,
    marginBottom: 30,
  },
  subtitle: {
    fontSize: 18,
    color: '#a813af', // vivid pink for subtitles
    textAlign: 'center',
    marginBottom: 20,
  },
  tip: {
    fontSize: 16,
    color: '#ffffff', // white for better readability
    textAlign: 'left',
    marginBottom: 10,
  },
  demoImage: {
    width: '100%',
    height: undefined,
    aspectRatio: 1.5, // Adjust the aspect ratio to your image
    resizeMode: 'contain',
    borderRadius: 15, // optional: if you want to round the corners of the image
    marginBottom: 30,
  },
  button: {
    backgroundColor: '#3baee5', // bright blue for buttons
    borderRadius: 50,
    paddingHorizontal: 30,
    paddingVertical: 15,
    marginTop: 10,
    elevation: 5, // slight shadow for button
  },
  buttonText: {
    color: '#1c0436', // dark purple text to contrast the button
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default GuideScreen;
