import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Image } from 'react-native';

import IntroScreen from './IntroScreen';
import GuideScreen from './GuideScreen';
import CaptureScreen from './CaptureScreen';
import ResultScreen from './ResultScreen';
import TutorialScreen from './TutorialScreen';
import ConfirmScreen from './ConfirmScreen';

const Stack = createStackNavigator();

const AppNavigator = () => {
  const imageHeight = 40; // Adjust this value to fit your header's height

  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName="Intro"
        screenOptions={{
          headerStyle: {
            backgroundColor: '#1D0236',
          },
          headerTintColor: '#3baee5',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
          headerBackTitleVisible: false,
          headerTitleAlign: 'center',
        }}
      >
        <Stack.Screen
          name="Intro"
          component={IntroScreen}
          options={{
            headerTitle: (props) => (
              <Image
                style={{
                  width: 130, // Adjust the width as necessary
                  height: imageHeight, // Use the defined height here
                }}
                resizeMode="contain"
                source={require('./assets/icon.png')}
              />
            ),
            headerTitleAlign: 'center',
            headerLeft: null // Removes the back button on the intro screen
          }}
        />
        <Stack.Screen name="Guide" component={GuideScreen} />
        <Stack.Screen name="Capture" component={CaptureScreen} />
        <Stack.Screen name="Result" component={ResultScreen} />
        <Stack.Screen name="Tutorial" component={TutorialScreen} />
        <Stack.Screen name="Confirm" component={ConfirmScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigator;
