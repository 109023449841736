import React, { useRef, useState } from 'react';
import { Text, View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import { Camera } from 'expo-camera';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';

export default function CaptureScreen() {
  const camRef = useRef(null);
  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);
  const navigation = useNavigation();

  const insets = useSafeAreaInsets();

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        const { status } = await Camera.requestCameraPermissionsAsync();
        setHasPermission(status === 'granted');
      })();
      return () => setHasPermission(null);
    }, [])
  );

  const takePicture = async () => {
    if (camRef.current) {
      const options = { quality: 0.5, base64: true, skipProcessing: true };
      const data = await camRef.current.takePictureAsync(options);

      let imageUri;
      if (Platform.OS === 'web') {
        // Check and log the base64 string
        console.log("Base64 String: ", data.base64);

        // Remove prefix if present (e.g., "data:image/jpeg;base64,")
        const base64Format = data.base64.split(',')[1] || data.base64;

        // Convert base64 string to a Blob
        const byteCharacters = atob(base64Format);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        imageUri = URL.createObjectURL(blob);
      } else {
        imageUri = data.uri;
      }

      navigation.navigate('Confirm', { imageUri });
    }
  };

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text style={styles.errorText}>No access to camera</Text>;
  };

  return (
    <SafeAreaView style={styles.container}>
      <Camera 
        style={styles.camera} 
        type={type} 
        ref={camRef}
        ratio="16:9"
        resizeMode="cover"
      >
        {/* Camera UI elements go here */}
      </Camera>
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.captureButton} onPress={takePicture}>
          <Text style={styles.buttonText}>Capture</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1c0436', // Dark purple background
  },
  camera: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 20,
  },
  captureButton: {
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: 'white', // Outer ring - white
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 3,
    borderColor: '#fff',
  },
  innerCircle: {
    width: 60, 
    height: 60, 
    borderRadius: 30,
    backgroundColor: '#ff3b30',
  },
  buttonText: {
    fontSize: 18,
    color: '#fff',
  },
  errorText: {
    textAlign: 'center',
    color: '#a813af', // Logo theme pink color
    fontSize: 18,
    marginTop: 20,
  },
});
