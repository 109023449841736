import React, { useState, useEffect } from 'react';
import { View, Image, TouchableOpacity, Text, StyleSheet, Alert, ActivityIndicator, Platform } from 'react-native';

export default function ConfirmScreen({ route, navigation }) {
  const { imageUri } = route.params;
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!imageUri) {
      console.error("No image URI provided.");
    }
  }, [imageUri]);

  const uploadImage = async () => {
  setUploading(true);
  const apiUrl = 'https://palm-read-api-2gu5ht2yqa-uc.a.run.app/upload';
  const formData = new FormData();

  try {
    if (Platform.OS === 'web') {
      const fetchResponse = await fetch(imageUri); // Renamed to fetchResponse
      const blob = await fetchResponse.blob();
      const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
      formData.append('file', file); // Directly append the file object
    } else {
      formData.append('file', {
        name: 'photo.jpg',
        type: 'image/jpeg',
        uri: imageUri,
      });
    }

    const response = await fetch(apiUrl, {
      method: 'POST',
      body: formData,
      // Content-Type header removed to let the browser set it automatically
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseJson = await response.json();
    setUploading(false);
    navigation.navigate('Result', { analysis: responseJson });
  } catch (error) {
    console.error('Upload failed:', error);
    setUploading(false);
    Alert.alert('Upload failed, please try again.');
  }
};

  return (
    <View style={styles.container}>
      {imageUri ? (
        <Image source={{ uri: imageUri }} style={styles.image} resizeMode="contain" />
      ) : (
        <ActivityIndicator size="large" color="#3baee5" />
      )}
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.button} onPress={() => navigation.goBack()}>
          <Text style={styles.buttonText}>Retake</Text>
        </TouchableOpacity>
        {uploading ? (
          <ActivityIndicator size="large" color="#3baee5" />
        ) : (
          <TouchableOpacity style={styles.button} onPress={uploadImage}>
            <Text style={styles.buttonText}>Proceed</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1c0436', // Dark purple background
  },
  image: {
    width: '100%',
    height: '70%',
    marginBottom: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    paddingHorizontal: 10,
  },
  button: {
    backgroundColor: '#3baee5', // Logo theme blue color
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2, // for Android shadow
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
});