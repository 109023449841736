import React, { useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Share } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';

const extractDetails = (content) => {
  // Define regex patterns for each line, allowing for optional asterisks and various whitespace
  const loveLifePattern = /(?:\*?\bLove life\b\*?\s*:)(.*?)(?=\*?\bCareer\b\*?\s*:|\*?\bHealth\b\*?\s*:|$)/is;
  const careerPattern = /(?:\*?\bCareer\b\*?\s*:)(.*?)(?=\*?\bLove life\b\*?\s*:|\*?\bHealth\b\*?\s*:|$)/is;
  const healthPattern = /(?:\*?\bHealth\b\*?\s*:)(.*?)(?=\*?\bLove life\b\*?\s*:|\*?\bCareer\b\*?\s*:|$)/is;

  // Extract the details using the patterns
  const loveLifeMatch = loveLifePattern.exec(content);
  const careerMatch = careerPattern.exec(content);
  const healthMatch = healthPattern.exec(content);

  // Check if all matches are successful and not empty
  if (loveLifeMatch && careerMatch && healthMatch) {
    // Extracted text, removing extra spaces and new lines
    const loveLifeText = loveLifeMatch[1].trim().replace(/[\r\n]+/g, ' ');
    const careerText = careerMatch[1].trim().replace(/[\r\n]+/g, ' ');
    const healthText = healthMatch[1].trim().replace(/[\r\n]+/g, ' ');

    return { loveLifeText, careerText, healthText };
  }

  // Return null if any of the interpretations cannot be extracted
  return null;
};


const ResultScreen = ({ route, navigation }) => {
  const { analysis } = route.params;

  console.log(analysis);

  const { content } = analysis.choices[0].message;

  // Use the extractDetails function to get interpretations
  const interpretations = extractDetails(content);

  // Define a resultText that will be used for sharing
  let resultText = "";
  if (interpretations) {
    const { loveLifeText, careerText, healthText } = interpretations;
    resultText = `Love Life Interpretation: ${loveLifeText}\n
      Career Interpretation: ${careerText}\n
      Health Interpretation: ${healthText}\n
      This was my result on Palm Reader!`;
  } else {
    resultText = "No palm detected. Please try another image.";
  }


  const onShare = async () => {
    try {
      await Share.share({
        message: resultText,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity onPress={onShare}>
          <Ionicons 
            name="ios-share-social" 
            size={25} 
            color="#3baee5" // Adjust the color to match the logo theme color
            style={{ marginRight: 10 }}
          />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  const Section = ({ title, children }) => (
    <View style={styles.section}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.divider} />
      {children}
    </View>
  );

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView contentContainerStyle={styles.container}>
        {interpretations ? (
          <>
            <Section title="Love Life Interpretation">
              <Text style={styles.result}>{interpretations.loveLifeText}</Text>
            </Section>

            <Section title="Career Interpretation">
              <Text style={styles.result}>{interpretations.careerText}</Text>
            </Section>

            <Section title="Health Interpretation">
              <Text style={styles.result}>{interpretations.healthText}</Text>
            </Section>
          </>
        ) : (
          <View style={styles.section}>
            <Text style={styles.result}>{resultText}</Text>
          </View>
        )}

        <TouchableOpacity style={styles.button} onPress={() => navigation.popToTop()}>
          <Text style={styles.buttonText}>Start Over</Text>
        </TouchableOpacity>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#1c0436', // Use background color to match the theme
  },
  container: {
    flexGrow: 1,
    padding: 20,
  },
  section: {
    backgroundColor: '#2e2952', // A slightly lighter shade for the section background
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
    shadowColor: '#000', // Shadow for depth
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  title: {
    fontSize: 22,
    color: '#fff',
    fontWeight: 'bold',
  },
  divider: {
    borderBottomColor: '#3baee5', // Divider color to match the logo theme
    borderBottomWidth: 2,
    marginVertical: 10,
  },
  result: {
    fontSize: 18,
    color: '#f2f2f2',
    marginBottom: 10,
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#3baee5',
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 20,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
});

export default ResultScreen;
