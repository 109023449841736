import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ImageBackground, Alert, Platform } from 'react-native';
import bgImage from './assets/palm.jpg';

const IntroScreen = ({ navigation }) => {
  const showDisclaimer = () => {
  if (Platform.OS === 'web') {
    window.alert("This app is intended for entertainment purposes only and does not provide true palm reading functionality.");
  } else {
    Alert.alert(
      "Disclaimer",
      "This app is intended for entertainment purposes only and does not provide true palm reading functionality.",
      [{ text: "OK", onPress: () => console.log("OK Pressed") }],
      { cancelable: false }
    );
  }
};


  return (
    <ImageBackground
      source={bgImage} 
      style={styles.container}
      imageStyle={{ opacity: 0.5 }}>
      <View style={styles.content}>
        <Text style={styles.title}>Welcome to Palm Reader</Text>
        <Text style={styles.subtitle}>
          Discover the mysteries of your future, learn about your life secrets and personal characteristics. 
          Our app analyses the lines in your palm to reveal accurate and meaningful insights.
        </Text>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Guide')}>
          <Text style={styles.buttonText}>Get Started</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttonSecondary} onPress={showDisclaimer}>
          <Text style={styles.buttonSecondaryText}>Disclaimer</Text>
        </TouchableOpacity>
        <Text style={styles.madeByText}>Made by Alex Choi</Text>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  content: {
    backgroundColor: '#1c0436', // Dark purple background
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    width: '100%', // Ensure the content container fills the width
  },
  title: {
    fontSize: 28,
    color: '#3baee5', // Bright blue color for the title text
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16, // Smaller font size for readability
    color: '#fff',
    textAlign: 'center',
    marginBottom: 20,
  },
  button: {
    backgroundColor: '#3baee5', // Bright blue for the primary button
    borderRadius: 30, // More rounded corners
    paddingHorizontal: 30,
    paddingVertical: 10,
    marginTop: 10,
    width: '80%', // Set a fixed width for consistency
    justifyContent: 'center', // Center text horizontally
    alignItems: 'center', // Center text vertically
  },
  buttonText: {
    color: '#1c0436', // Dark purple text for contrast on bright blue button
    fontSize: 18,
    fontWeight: 'bold',
  },
  buttonSecondary: {
    backgroundColor: 'transparent',
    borderRadius: 30,
    paddingHorizontal: 30,
    paddingVertical: 10,
    marginTop: 10,
    borderColor: '#a813af', // Vivid pink border
    borderWidth: 2,
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonSecondaryText: {
    color: '#a813af', // Vivid pink text to match the secondary button border
    fontSize: 18,
    fontWeight: 'bold',
  },
  madeByText: {
    color: '#aaa',
    fontSize: 12,
    marginTop: 15,
  },
});

export default IntroScreen;
